import request from '@manage/utils/request'

export function handlePageMatchs(data) { //分页查询比赛
	return request({
		url: 'admin/matchinfomanage/pageMatchs',
		method: 'post',
		data
	})
}


export function handleSetMatchFee(data) { //设置费用
	return request({
		url: 'admin/matchinfomanage/setMatchFee',
		method: 'post',
		data
	})
}



export function handleMatchinfoDetails(id) { //比赛详情
	return request({
		url: 'matchinfo/details',
		method: 'post',
		params: {
			id: id
		}
	})
}


export function handleEndMatchInfo(matchId) { //结束比赛
	return request({
		url: 'admin/matchinfomanage/endMatchInfo',
		method: 'post',
		params: {
			matchId: matchId
		}
	})
}


export function handleSetPayState(data) { //设置支付状态
	return request({
		url: 'admin/matchinfomanage/setPayState',
		method: 'post',		
		data
	})
}

export function handleSetMatchState(data) { //设置比赛状态
	return request({
		url: 'admin/matchinfomanage/setMatchState',
		method: 'post',
		data
	})
}


export function handleModifyMatchServiceFee(data) { //修改比赛费用
	return request({
		url: 'admin/matchinfomanage/modifyMatchServiceFee',
		method: 'post',
		data
	})
}

export function handleModifyMathcPlayerAuthFee(data) { //修改实名认证费用
	return request({
		url: 'admin/matchinfomanage/modifyMathcPlayerAuthFee',
		method: 'post',
		data
	})
}



export function handleModifyMathcAuthPreFee(data) { //修改实名认证预付费（比赛方充值的实名认证费用）
	return request({
		url: 'admin/matchinfomanage/modifyMathcAuthPreFee',
		method: 'post',
		data
	})
}


