import {
	Message
} from 'element-ui'

import {
	handlePageMatchs,
	handleSetMatchFee,
	handleMatchinfoDetails,
	handleEndMatchInfo,
	handleSetPayState,
	handleSetMatchState,
	handleModifyMatchServiceFee,
	handleModifyMathcPlayerAuthFee,
	handleModifyMathcAuthPreFee
} from '@manage/api/bm/matchesmanage';
export default {
	name: 'matchesmanage',
	components: {},
	data() {
		return {
			state: ["报名中", "报名截止", "比赛中", "比赛结束"],
			states: [{ label: "全部", value: '' }, { label: "报名中", value: '0' }, { label: "报名截止", value: '1' }, { label: "比赛中", value: '2' }, { label: "比赛结束", value: '3' }],
			payState: ["未支付", "推迟支付", "线上已支付", "线下已支付", "免费使用"],
			payStates: [{ label: "全部", value: '' }, { label: "未支付", value: '0' }, { label: "推迟支付", value: '1' }, { label: "线上已支付", value: '2' }, { label: "线下已支付", value: '3' }, { label: "免费使用", value: '4' }],
			queryForm: { state: '', payState: '', con: '' },
			setFeeForm: {},
			setFeeDialogVisible: false,
			setPayStatesDialogVisible: false,
			setStateDialogVisible: false,
			setMatchServiceFeeDialogVisible: false,
			setPlayerAuthFeeDialogVisible: false,
			setMatchAuthPreFeeDialogVisible: false,
			setPayStateForm: { payState: '' },
			setStateForm: { state: "" },
			setMatchServiceFeeForm: { matchServiceFee: 0 },
			setPlayerAuthFeeForm: { playerAuthFee: 0 },
			setAuthPreFeeForm: { authPreFee: 0 },
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			tableData: [],
			setFeeRules: {
				matchServiceFee: [{
					required: true,
					message: '请输入服务费',
					trigger: 'blur'
				}],
				playerAuthFee: [{
					required: true,
					message: '请输入选手认证费',
					trigger: 'blur'
				}]
			},
		}
	},
	watch: {

	},
	created() {
		this.fastQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageMatchs({
				pageSize: this.pageSize,
				currPage: this.currPage,
				organizer: this.queryForm.organizer,
				matchName: this.queryForm.matchName,
				state: this.queryForm.state,
				payState: this.queryForm.payState,
				con: this.queryForm.con,
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		opensetFeeDialog(id, payState) { //设置费用  || '3' == payState
			if ('2' == payState) {
				Message({
					message: '已支付数据不能修改!',
					type: 'error',
					duration: 5 * 1000
				})
				return;
			}
			this.setFeeDialogVisible = true;
			handleMatchinfoDetails(id).then(res => {
				if (res.status == 200) {
					this.setFeeForm = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		details(matchId) { //查看详情
			sessionStorage.setItem("currMatchId", matchId);
			let routeData = this.$router.resolve({
				path: '/matchinfo',
				query: {
					menusShow: true
				}
			});
			window.open(routeData.href, '_blank');
		},
		submitInfo() { //保存费用
			this.setFeeForm.matchId = this.setFeeForm.id
			handleSetMatchFee(this.setFeeForm).then(res => {
				if (res.status == 200) {
					this.setFeeDialogVisible = false;
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.setFeeForm = {};
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		endMatchInfo(matchId) {//结束比赛
			handleEndMatchInfo(matchId).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		resetForm() {
			this.queryForm = { state: '', payState: '' };
			this.fastQuery();
		},
		selectPayState(id, payState) {
			// if (payState == '2') {//线上已支付
			// 	return;
			// }
			this.setPayStatesDialogVisible = true;
			this.setPayStateForm = { id: id, payState: payState };
		},
		selectState(id, state) {
			// if (state == '3') {//比赛结束
			// 	return;
			// }
			this.setStateDialogVisible = true;
			this.setStateForm = { id: id, state: state };
		},
		selectMatchServiceFee(id, payState, matchServiceFee) {
			// if (payState == '2' || payState == '3' || payState == '4') {//2-线上已支付  3-线下已支付 4-免费使用
			// 	return;
			// }
			this.setMatchServiceFeeDialogVisible = true;
			this.setMatchServiceFeeForm = { id: id, payState: payState, matchServiceFee: matchServiceFee };
		},
		selectPlayerAuthFee(id, playerAuthFee) {
			this.setPlayerAuthFeeDialogVisible = true;
			this.setPlayerAuthFeeForm = { id: id, playerAuthFee: playerAuthFee };
		},
		submitPayStateForm() {//设置支付状态
			handleSetPayState({ matchId: this.setPayStateForm.id, payState: this.setPayStateForm.payState }).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.setPayStatesDialogVisible = false;
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		submitMatchStateForm() {  //设置比赛状态
			handleSetMatchState({ matchId: this.setStateForm.id, state: this.setStateForm.state }).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.setStateDialogVisible = false;
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},


		submitMatchServiceFeeForm() {
			handleModifyMatchServiceFee({
				matchId: this.setMatchServiceFeeForm.id,
				matchServiceFee: this.setMatchServiceFeeForm.matchServiceFee
			}).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.setMatchServiceFeeDialogVisible = false;
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		submitPlayerAuthFeeForm() {
			handleModifyMathcPlayerAuthFee({
				matchId: this.setPlayerAuthFeeForm.id,
				playerAuthFee: this.setPlayerAuthFeeForm.playerAuthFee
			}).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.setPlayerAuthFeeDialogVisible = false;
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		selectMathcAuthPreFee(matchId, authPreFee) {
			this.setMatchAuthPreFeeDialogVisible = true;
			this.setAuthPreFeeForm = { matchId: matchId, authPreFee: authPreFee };
		},
		modifyMathcAuthPreFeeOk() {
			handleModifyMathcAuthPreFee(this.setAuthPreFeeForm).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					});
					this.setMatchAuthPreFeeDialogVisible = false;
					this.setAuthPreFeeForm = {authPreFee: 0 };
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		}


	}
}